@tailwind base;
@tailwind components;

html {
	@apply font-body;
	@apply bg-gray-97;
}
body {
	overflow-x: hidden;
	min-width: 320px;
}
ul li {
	position: relative;
	padding-left: 25px;
}
ul li::before {
	content: '';
	position: absolute;
	top: 6px;
	left: 0;
	width: 10px;
	height: 10px;
	border-radius: 4px;
	@apply bg-primary;
}
code {
	font-family: monospace;
	@apply border-primary-lightest border rounded font-normal;
	background-color: #dcfaf3;
	white-space: nowrap;
	padding: 1px 3px 2px;
	margin-right: 3px;
	position: relative;
	top: -1px;
	font-size: 0.85em;
	/* line-height: 48px; */
}
.App {
	position: relative;
	/* background-image: url('images/snow-bg.png'); */
	/* background-size: cover; */
}
.App::before,
.App::after {
	background-size: 40vw;
	background-repeat: no-repeat;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.75;
	z-index: 1;
}
.App::before {
	background-image: url('images/stars-bg-left.jpg');
	background-position: bottom 10% left;
}
.App::after {
	background-image: url('images/stars-bg-right.jpg');
	background-position: top right;
}

@media (min-width: 800px) {
	.App::before,
	.App::after {
		background-size: 35vw;
	}
}

@media (min-width: 1200px) {
	.App::before,
	.App::after {
		background-size: 25vw;
	}
}

.content {
	position: relative;
}

@media (min-width: 800px) {
	.content::after {
		content: '';
		position: absolute;
		top: -10%;
		left: -10%;
		bottom: -10%;
		right: -10%;
		background: radial-gradient(#f7f7f7 60%, rgba(255, 255, 255, 0.00001));
		opacity: 0.75;
		z-index: -1;
	}
}

button,
.btn {
	@apply px-4 py-2 bg-primary rounded text-white rounded-full inline-block uppercase font-bold hover_bg-secondary transition duration-300;
	min-width: 220px;
	padding: 9px 26px 7px;
}
button[disabled] {
	@apply bg-gray-90;
	cursor: default;
}
.btn--lg {
	padding: 16px 30px 15px;
}
.h1 {
	@apply text-lg md_text-xl font-display font-bold uppercase;
	line-height: 1.4;
}
.h1--w {
	@apply text-white bg-gradient-to-bl;
	-webkit-background-clip: text;
	background-image: linear-gradient(135deg, #3cdbc0 0%, #3cdbc0 25%, #d673e8 75%, #d673e8 100%);
	-webkit-text-fill-color: rgba(255, 255, 255, 0.00001);
}
.h2 {
	@apply text-lg uppercase;
}
.container {
	@apply px-4;
}
#canvas {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
}
::selection {
	@apply bg-secondary	text-white;
}

.inp-item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding-left: 27px;
	cursor: pointer;
}
.inp-item__inp[type='hidden'] + .inp-item__text {
	position: relative;
	left: -27px;
}
.inp-item--option {
	display: flex;
}

.inp-item--option,
.inp-item--option .inp-item__text {
	cursor: default;
}
.inp-item--option .inp-item__text::before {
	display: none;
}
.inp-item--option .inp-item__text::after {
	display: none;
}

.inp-item--option .inp-item__inp:not([type='hidden']),
.inp-item--option .inp-item__inp:not([type='hidden']) ~ .inp-item__text {
	cursor: pointer;
}
.inp-item--option .inp-item__inp:not([type='hidden']) ~ .inp-item__text::before {
	display: block;
}
.inp-item--option .inp-item__inp:not([type='hidden']) ~ .inp-item__text::after {
	display: block;
}
.inp-item--option .inp-item__inp:not([type='hidden']) ~ .inp {
	cursor: pointer;
}

.inp-item--option .inp-item__inp:not([type='hidden']) ~ .inp::after {
	content: '\01F389';
	font-size: 2em;
	position: absolute;
	transform: translate(10px, -25%);
}
.answers .block:nth-child(2) .inp-item--option .inp-item__inp:not([type='hidden']) ~ .inp::after {
	content: '\01F648';
	font-size: 2em;
	position: absolute;
	transform: translate(10px, -25%);
}

.inp-item__inp {
	position: absolute;
	left: -5000px;
}

.inp-item__text {
	display: block;
	line-height: (24/16);
	cursor: pointer;
	user-select: none;
}
.inp-item__text::before {
	content: '';
	position: absolute;
	top: 1px;
	left: 0;
	width: 18px;
	height: 18px;
	border: 2px solid #5c5c5c;
	border-radius: 3px;
	background-color: #fff;
	transition: background-color 0.3s, border-color 0.3s;
}
.inp-item__text::after {
	content: '';
	position: absolute;
	top: 6px;
	left: 4px;
	width: 10px;
	height: 5px;
	border: 1px solid #fff;
	border-width: 0 0 2px 2px;
	opacity: 0;
	transform: rotate(-45deg);
	transition: opacity 0.3s;
}
.inp-item__title {
	display: block;
}

.inp-item__inp:focus + .inp-item__text::before {
	border-color: hsl(0, 0%, 36%);
}
.inp-item__inp:checked + .inp-item__text::before {
	@apply bg-primary border-primary;
}
.inp-item__inp:checked + .inp-item__text::after {
	opacity: 1;
}

.inp-item--radio .inp-item__text::before {
	top: 1px;
	width: 18px;
	height: 18px;
	border-radius: 20px;
}
.inp-item--radio .inp-item__text::after {
	top: 7px;
	left: 6px;
	width: 6px;
	height: 6px;
	border: 0;
	border-radius: 5px;
	background-color: #fff;
	transform: none;
}
.count {
	@apply bg-white border-primary relative flex justify-center items-center;
	border-radius: 50%;
}
.count img {
	border-radius: 50%;
}
.count::before {
	z-index: -1;
	content: '';
	position: absolute;
	top: -5px;
	left: -5px;
	right: -5px;
	bottom: -5px;
	border-radius: 50%;
	background-image: linear-gradient(135deg, #3cdbc0 0%, #3cdbc0 25%, #d673e8 75%, #d673e8 100%);
	animation: animate 1s infinite;
}
@keyframes animate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.wrong-answer {
	position: relative;
}
.wrong-answer::before {
	content: '\01F626';
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, -125%);
}

.wrong-answer.time-9::before {
	content: '\01F621';
}

.wrong-answer.time-8::before {
	content: '\01F620';
}

.wrong-answer.time-7::before {
	content: '\01F625';
}

.wrong-answer.time-6::before {
	content: '\01F61E';
}

.wrong-answer.time-5::before {
	content: '\01F612';
}

.wrong-answer.time-4::before {
	content: '\01F60F';
}

.wrong-answer.time-3::before {
	content: '\01F60C';
}

.wrong-answer.time-2::before {
	content: '\01F60B';
}

.wrong-answer.time-1::before {
	content: '\01F603';
}

.mobile-warning {
	background-color: #d81316;
	color: #fff;
	margin-left: -1rem;
	margin-right: -1rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

@media (min-width: 1120px) {
	.mobile-warning {
		display: none;
	}
}

@tailwind utilities;
